import { PaginationProps } from "~/components/layout/Pagination";

export const DOTS = "...";

const range = (start, end) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

/**
 * Creates range with current pagination
 *
 * @param dataCount  total number of data
 * @param pageIndex  current page number
 * @param paginate  number of data per page
 * @param visiblePages  number of pages visible
 */
export const usePaginationLogic = ({ dataCount, paginate, pageIndex, visiblePages }: PaginationProps) => {
  const totalPageCount = Math.ceil(dataCount / paginate);
  const totalNumbers = visiblePages + 2; // for the first and last page buttons
  const startPage = Math.max(2, pageIndex - Math.floor(visiblePages / 2));
  const endPage = Math.min(totalPageCount - 1, startPage + visiblePages - 1);

  let pages = range(startPage, endPage);

  if (startPage > 2) {
    pages = [DOTS, ...pages];
  }
  if (endPage < totalPageCount - 1) {
    pages = [...pages, DOTS];
  }

  return totalPageCount <= 1 ? [1] : [1, ...pages, totalPageCount];
};

/**
 * Creates range with current pagination
 *
 * @param nbPages  total number of pages
 * @param currentRefinement  current page number
 * @param pages  page range returned for pagination
 * @param visiblePages  number of pages visible
 */
export const useSearchV2PaginationLogic = ({ nbPages, currentRefinement, pages: pagesB, visiblePages }) => {
  const pages = visiblePages === 1 ? [currentRefinement] : pagesB;
  if (nbPages <= 7) {
    return pages;
  }

  if (currentRefinement <= 3 && currentRefinement <= nbPages - 5 && nbPages > 7) {
    return [...pages, DOTS, nbPages - 1];
  }

  if (currentRefinement > 3 && currentRefinement <= nbPages - 5 && nbPages > 7) {
    return [0, DOTS, ...pages, DOTS, nbPages - 1];
  }

  if (currentRefinement > nbPages - 5 && nbPages > 7) {
    return [0, DOTS, ...pages];
  }
};
